<template>
  <v-container
    id="blotter-list-container"
    fluid
    tag="section"
    class="full-height"
  >
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          class="ml-10 mr-10"
          v-model="selectedAccessId"
          :disabled="loading"
          :items="accounts"
          color="primary"
          auto-select-first
          label="Select Account"
          item-text="name"
          item-value="accessId"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <blotter-component-list
          ref="dailyBlotter"
          :key="1"
          type="DAILY"
          :access-id="selectedAccessId"
          :user-id="selectedUserId"
          title="blotters"
          :loading="loading"
          :tabs-data="tabsData.daily.current"
          :tabs-data-history="tabsData.daily.history"
          @start-uploading="startUploadFile"
        />
      </v-col>
      <v-col cols="6">
        <blotter-component-list
          ref="monthlyBlotter"
          :key="2"
          type="MONTHLY"
          :access-id="selectedAccessId"
          :user-id="selectedUserId"
          title="monthly reports"
          :loading="loading"
          :tabs-data="tabsData.monthly.current"
          :tabs-data-history="tabsData.monthly.history"
          @start-uploading="startUploadFile"
        />
      </v-col>
    </v-row>
    <div class="text-center">
      <v-dialog
        v-model="dialogAlreadyUpload"
        width="500"
      >
        <v-card>
          <v-card-title class="headline lighten-2">
            <span
              class="red--text text-uppercase"
              style="letter-spacing: 0.3rem;"
            >Error</span>
          </v-card-title>

          <v-card-text
            align="center"
          >
            Cannot upload new blotter. A review for the blotter from the current period has already started!
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="dialogAlreadyUpload = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog
        v-model="dialogTimeExpired"
        width="500"
      >
        <v-card>
          <v-card-title class="headline lighten-2">
            <span
              class="red--text text-uppercase"
              style="letter-spacing: 0.3rem;"
            >Error</span>
          </v-card-title>

          <v-card-text
            align="center"
          >
            Cannot upload new blotter. Time for this expired. Valid period is from 23:00 to 13:00 next business day!
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="dialogTimeExpired = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
import blotterService from '@/service/BlotterService'
import BlotterComponentList from './BlotterComponentList'

export default {
  name: 'BlotterList',
  components: {
    BlotterComponentList
  },
  data: () => ({
    selectedAccessId: 0,
    selectedUserId: 0,
    accounts: [],
    loading: false,
    tabsData: {
      daily: {
        current: [],
        history: {}
      },
      monthly: {
        current: [],
        history: {}
      }
    },
    dialogAlreadyUpload: false,
    dialogTimeExpired: false
  }),
  watch: {
    async selectedAccessId (val) {
      this.selectedUserId = this.accounts.filter(i => i.accessId === val)[0].userId
      await this.fetchBlotterDashboard(val)
    }
  },
  beforeMount () {
    this.loading = true
    this.fetchAccounts()
    this.loading = false
  },
  methods: {
    async fetchAccounts () {
      const response = await blotterService.fetchAccounts()
      if (response.status === 200) {
        this.accounts = response.data
        this.selectedAccessId = this.accounts[0].accessId
      }
    },
    async fetchBlotterDashboard (val) {
      const response = await blotterService.fetchBlotterDashboard(val || this.selectedAccessId)
      if (response.status === 200) {
        this.tabsData = response.data
      }
    },
    async startUploadFile ({ accessId, userId, type, file, date }) {
      this.loading = true
      let response = await blotterService.uploadBlotterFile({ file, accessId, userId, type, date })
      if (response.status === 200) {
        response = await blotterService.fetchBlotterDashboard(this.selectedAccessId)
        if (response.status === 200) {
          this.tabsData = response.data
        }
        if (type === 'DAILY') {
          this.$refs.dailyBlotter.clearUploadFile()
        } else {
          this.$refs.monthlyBlotter.clearUploadFile()
        }
      } else if (response.status === 409) {
        if (response.error && response.error.errorCode === 'CannotUploadNewBlotterAlreadyStartReviewException') {
          this.dialogAlreadyUpload = true
        }
        if (response.error && response.error.errorCode === 'CannotUploadNewBlotterTimeLimitException') {
          this.dialogTimeExpired = true
        }
      }
      this.loading = false
    }
  }
}
</script>
<style scoped>

.tab-header {
  font-weight: 400;
  letter-spacing: 0.3rem;
}
</style>
