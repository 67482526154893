<template>
  <v-container
    id="blotter-component-list-container"
    fluid
    tag="section"
    class="full-height"
  >
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col
            cols="12"
            class="text-center"
          >
            <h3 class="text-uppercase tab-header">
              {{ $props.title }}
            </h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs
              v-model="tab"
              fixed-tabs
            >
              <v-tab :disabled="$props.loading">
                <span class="tab-header">Current</span>
              </v-tab>
              <v-tab :disabled="$props.loading">
                <span class="tab-header">History</span>
              </v-tab>
            </v-tabs>
            <v-tabs-items
              v-model="tab"
              class="pb-1"
            >
              <v-tab-item style="min-height: 600px">
                <v-data-table
                  style="min-height: 600px; min-width: 100px"
                  :items="$props.tabsData"
                  :items-per-page="31"
                  :headers="headers"
                  :loading="$props.loading"
                  :disable="$props.loading"
                  hide-default-footer
                  hide-default-header
                  :disable-sort="true"
                  loading-text="Loading... Please wait"
                  class="elevation-1 row-pointer"
                  @click:row="clickRow"
                >
                  <template v-slot:item.reviewedByManager="{ item }">
                    <div>
                      <v-icon
                        large
                        :color="item.reviewByManager ? 'green darken-2' : 'orange accent-1'"
                      >
                        {{ item.reviewByManager ? (item.acknowledgeManagerReview ? 'mdi-check-all' : 'mdi-check') : 'mdi-clock' }}
                      </v-icon>
                      <span class="grey--text darken-4 ml-3 pt-2">
                        {{ `${item.reviewByManager ? 'Manager review DONE' : 'WAITING manager review'}` }}
                      </span>
                    </div>
                  </template>
                  <template v-slot:item.reviewedByOwner="{ item }">
                    <div>
                      <v-icon
                        large
                        :color="item.reviewByOwner ? 'green darken-2' : 'orange accent-1'"
                      >
                        {{ item.reviewByOwner ? (item.acknowledgeSeniorManagerReview ? 'mdi-check-all' : 'mdi-check') : 'mdi-clock' }}
                      </v-icon>
                      <span class="grey--text darken-4 ml-3 pt-2">
                        {{ `${item.reviewByOwner ? 'Senior manager review DONE' : 'WAITING senior manager review'}` }}
                      </span>
                    </div>
                  </template>
                </v-data-table>
              </v-tab-item>
              <v-tab-item style="min-height: 600px">
                <v-expansion-panels
                  focusable
                  accordion
                  :disabled="$props.loading"
                >
                  <v-expansion-panel
                    v-for="(key, i) in Object.keys($props.tabsDataHistory || {})"
                    :key="i"
                  >
                    <v-expansion-panel-header>
                      {{ key }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-data-table
                        :items="$props.tabsDataHistory[key]"
                        :headers="headers"
                        :items-per-page="31"
                        :loading="$props.loading"
                        :disable="$props.loading"
                        hide-default-footer
                        :disable-sort="true"
                        loading-text="Loading... Please wait"
                        class="elevation-1 row-pointer"
                        @click:row="clickRow"
                      >
                        <template v-slot:item.reviewedByManager="{ item }">
                          <div>
                            <v-icon
                              large
                              :color="item.reviewByManager ? 'green darken-2' : 'orange accent-1'"
                            >
                              {{ item.reviewByManager ? (item.acknowledgeManagerReview ? 'mdi-check-all' : 'mdi-check') : 'mdi-clock' }}
                            </v-icon>
                            <span class="grey--text darken-4 ml-3 pt-2">
                              {{ `${item.reviewByManager ? 'Manager review DONE' : 'WAITING manager review'}` }}
                            </span>
                          </div>
                        </template>
                        <template v-slot:item.reviewedByOwner="{ item }">
                          <div>
                            <v-icon
                              large
                              :color="item.reviewByOwner ? 'green darken-2' : 'orange accent-1'"
                            >
                              {{ item.reviewByOwner ? (item.acknowledgeSeniorManagerReview ? 'mdi-check-all' : 'mdi-check') : 'mdi-clock' }}
                            </v-icon>
                            <span class="grey--text darken-4 ml-3 pt-2">
                              {{ `${item.reviewByOwner ? 'Senior manager review DONE' : 'WAITING senior manager review'}` }}
                            </span>
                          </div>
                        </template>
                      </v-data-table>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5">
            <v-file-input
              v-model="file"
              accept="application/pdf"
              label="Click to attach file *"
              counter
              show-size
              clearable
              class="pt-0"
            />
          </v-col>
          <v-col cols="4">
            <v-dialog
              ref="to"
              v-model="uploadDateModel"
              :return-value.sync="uploadDate"
              :disabled="loading"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="uploadDate"
                  class="mt-0 pt-0"
                  :disabled="loading"
                  label="For date *"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-if="type === 'MONTHLY'"
                v-model="uploadDate"
                :max="maxDay"
                type="month"
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="uploadDateModel = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.to.save(uploadDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
              <v-date-picker
                v-else
                v-model="uploadDate"
                :max="maxDay"
                scrollable
              >
                <v-spacer />
                <v-btn
                  text
                  color="primary"
                  @click="uploadDateModel = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.to.save(uploadDate)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col
            cols="3"
            class="text-right"
          >
            <v-btn
              color="primary"
              :disabled="loading"
              @click="eventUploadFile"
            >
              Upload Blotter
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-dialog
        v-model="dialog"
        width="500"
      >
        <v-card>
          <v-card-title class="headline lighten-2">
            <span
              class="red--text text-uppercase"
              style="letter-spacing: 0.3rem;"
            >Error</span>
          </v-card-title>

          <v-card-text
            align="center"
          >
            First attach file and choose date!
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>

export default {
  name: 'BlotterComponentList',
  components: {
  },
  props: {
    title: {
      type: String,
      default: 'Blotter'
    },
    type: {
      type: String,
      validated: (val) => ['daily', 'monthly'].indexOf(val) > -1
    },
    loading: {
      type: Boolean,
      default: false
    },
    accessId: {
      type: Number,
      default: -1
    },
    userId: {
      type: Number,
      default: -1
    },
    tabsData: {
      type: Array,
      default: () => []
    },
    tabsDataHistory: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    tab: 0,
    headers: [
      { text: 'Name', value: 'name', align: 'start' },
      { text: 'Review by manager', value: 'reviewedByManager', align: 'center' },
      { text: 'Review by owner', value: 'reviewedByOwner', align: 'center' }
    ],
    uploadDateModel: false,
    uploadDate: '',
    file: null,
    dialog: false
  }),
  computed: {
    maxDay: () => {
      return new Date().toISOString().substr(0, 10)
    }
  },
  beforeMount () {
  },
  methods: {
    clearUploadFile () {
      this.file = null
      this.uploadDate = ''
      this.uploadDateModel = false
    },
    showDialog () {
      this.dialog = true
    },
    hideDialog () {
      this.dialog = false
    },
    async clickRow ({ id }) {
      await this.$router.push(`/blotters/${id}`)
    },
    async eventUploadFile () {
      if (!this.file || !this.uploadDate) {
        this.dialog = true
      } else {
        this.$emit('start-uploading', { accessId: this.$props.accessId, userId: this.$props.userId, type: this.$props.type, file: this.file, date: this.uploadDate })
      }
    }
  }
}
</script>
<style scoped>
.tab-header {
  font-weight: 400;
  letter-spacing: 0.3rem;
}

.full-height {
  height: 100%;
}

.row-pointer :hover {
  cursor: pointer;
}

</style>
